import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import MuiLink from "@material-ui/core/Link"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
}))

const ConveyancingPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Conveyancing and property matters" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Conveyancing and Property Matters
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Property Purchase
            </Typography>

            <Typography component="p" paragraph>
              The big issue in your purchase of property is “caveat emptor” –
              this means “buyers beware” – you buy what you see. As the
              Purchaser you have the entire responsibility to work out exactly
              the condition of the property that you are buying and any problems
              that may come up which you would have to deal with.
            </Typography>

            <Typography component="p" paragraph>
              Before you purchase a property you should:
            </Typography>

            <Typography component="ol" paragraph>
              <Typography component="li">
                Obtain your Lender’s pre-approval for your loan so that you are
                quite sure as to your purchase price limits;
              </Typography>

              <Typography component="li">
                Ensure that you do not waiver your Cooling Off rights of 5
                business days (or 10 business days if you are purchasing off the
                plan) to change your mind on the property purchase; and,
              </Typography>

              <Typography component="li">
                Check the contract. We are happy to check a contract with you.
                Kindly ask your real estate agent to forward the contract to our
                email address{" "}
                <MuiLink
                  href="mailto:janicevusolicitor@gmail.com"
                  variant="body1"
                  color="secondary"
                >
                  janicevusolicitor@gmail.com
                </MuiLink>{" "}
                and we can give you our advice by email or in person.
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              How to check a property contract:
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              1. The contract should provide you with:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                A description of the property on the front page with inclusions
                and exclusions on the front page.
              </Typography>

              <Typography component="li">
                Confirmation whether GST is payable on the contract and whether
                you have to contribute to land tax.
              </Typography>

              <Typography component="li">
                Title particulars: This allows you to check that the Vendor is
                the correct persons selling you the property and also provides a
                check on any interests in the property e.g. caveat, easement,
                covenant etc.
              </Typography>

              <Typography component="li">
                Deposited plan or strata plan: This shows the dimension of the
                property. In a Strata Plan it also identifies whether your
                property has a separate title for a garage space. In strata
                plans, do check where the garbage disposal unit is so that it is
                not too close to your unit.
              </Typography>

              <Typography component="li">
                Drainage diagram: This shows the major Sydney Water drains and
                also domestic drains. Where a building is built over large
                drainage pipes you would need to check that the Vendor has the
                consent from Sydney Water for this purpose.
              </Typography>

              <Typography component="li">
                Section 149 Council zoning certificate: The information provided
                includes zoning requirements and Council records applicable to
                your property e.g. road widening, flood warnings, contaminated
                land.
              </Typography>

              <Typography component="li">
                Where there is a swimming pool or spa the Vendor needs to
                confirm that the swimming pool or spa it is registered with the
                Local Council and provide a valid certificate of compliance (or
                non-compliance) or have a valid occupation certificate less than
                3 years old.
              </Typography>

              <Typography component="li">
                Where you are purchasing off the plan, check the Vendor
                disclosure statement in the contract which should include:
                details of sunset clauses, conditional events, a proposed
                schedule of finishes, draft strata plan, and draft by-laws. It
                would also be useful to have the floor plan in the contract to
                check with the final build. Also check if there is a clause in
                the contract that allows you to rescind the contract where the
                dimensions of the property do not comply with the original draft
                plans.
              </Typography>

              <Typography component="li">
                Where the property is tenanted, a copy of the residential lease
                should be included in the contract.
              </Typography>
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              2. While you are negotiating the price, ask the Vendor and/or
              Agent the following questions:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                Whether there are any problems with the property that they know
                of.
              </Typography>

              <Typography component="li">
                Whether the Vendor or predecessors have conducted any building
                works on the Property after the original building was built, and
                if so, did they get Council consent for the new building work.
              </Typography>
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              3. Building report
            </Typography>

            <Typography component="p" paragraph>
              This report provides you with the details as to the structural
              situation of the property – whether there are major or minor
              cracks in the property, whether there is any leaking in the
              showers, laundry areas. You should then get a general estimate
              cost in repairing the damage noted in the report.
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              4. Pest report
            </Typography>

            <Typography component="p" paragraph>
              This report provides you with the details as to termites and
              borers. This report is essential as you do not want to hear
              termites eating away on your floorboards in the quiet of the
              night.
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              5. Survey
            </Typography>

            <Typography component="p" paragraph>
              A survey will tell you exactly the dimensions of the land and
              whether your property is fully built on your land and is not part
              on someone else’s property. You also do not wish to have a
              situation where your neighbour’s property is partly on your land.
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              6. Council building certificate
            </Typography>
            <Typography component="p" paragraph>
              This involves a Council inspection of the property to state that
              it fully complies with Council requirements. Where there have been
              additions to the property, the Council inspector has the option of
              stating (a) that the additions are OK and meet the Council
              requirements, or (b) they are not OK and need further work done,
              or (c) they are definitely not OK and must be pulled down. The
              Council will require a survey to be attached to the request for a
              Council building certificate. It can take 2-3 weeks to get the
              Council building certificate.
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              7. Additional searches after exchange of contracts
            </Typography>
            <Typography component="p" paragraph>
              Council rates, Water rates: These are standard searches to help
              organise settlement figures as the Vendor has to pay for his share
              of the Council rates, Water rates until the settlement date. You
              can also do additional searches from government authorities to
              check if they have an interest in your land e.g. outstanding
              health and building notice.
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              8. Prior to settlement, the Vendor is required to provide you
              with:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">Clear Land Tax certificate</Typography>
              <Typography component="li">
                Clear Foreign Resident Capital Gains Withholding Payments
                Certificate – This is mandatory for all property over $750,000.
                If the Certificate is not provided then the Purchaser must
                withhold 12.5% of the purchase price and forward this payment to
                the ATO.
              </Typography>
              <Typography component="li">
                If you are buying off the plan, check the contract that the
                Vendor is required to give you an occupation certificate prior
                to or on settlement
              </Typography>
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Purchasing as joint tenants or as tenants-in-common
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              A purchase by 2 or more persons as joint tenants indicate that:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                All parties own the property in equal shares; and,
              </Typography>
              <Typography component="li">
                There is a right of survivorship in that if one person passes
                away the property automatically belongs to the surviving party
                and if more than one in equal shares.
              </Typography>
            </Typography>

            <Typography component="h3" variant="h5" gutterBottom>
              A purchase by 2 or more persons as tenants-in-common indicate
              that:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                The property is owned separately by each party to the contract.
                The shareholding of each party can vary and be equal or unequal;
                and,
              </Typography>

              <Typography component="li">
                Where one party passes away their share of the property is
                distributed according to their Will.
              </Typography>
            </Typography>
            <Typography component="p" paragraph>
              A change of ownership from joints tenants to tenants-in-common
              between spouses and vice versa is stamp duty exempt.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Selling a property
            </Typography>

            <Typography component="p" paragraph>
              The Vendor can rely on the contract for all matters regarding the
              sale. There is generally a clause in the contract that states that
              the Purchaser is not able to rely on any representations by the
              Vendor or their Agent in the sale and can only rely on the
              Contract.
            </Typography>
            <Typography component="p" paragraph>
              The property details are indicated on the front page of the
              contract including all inclusions and exclusions for the property.
              Land tax and GST adjustments are listed on page 2 of the Contract.
              In addition the Contract should include:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                Certificate of Title search. For Strata also include the
                Certificate of Title for Common Property and copy of the current
                by-laws. For Strata property also provide for any Community
                Title interests on the property
              </Typography>
              <Typography component="li">
                Searches for all easements, and covenants affecting the land.
              </Typography>
              <Typography component="li">
                Deposited Plan/Strata Plan.
              </Typography>
              <Typography component="li">
                Drainage diagrams (x2): This should include the drainage diagram
                for the property and a second drainage diagram for the local
                area.
              </Typography>
              <Typography component="li">
                Section 149 Council zoning certificate
              </Typography>
              <Typography component="li">
                Where there is a swimming pool or spa, the Vendor needs to
                provide confirmation that the pool or spa is registered with
                Local Council, and provide a valid certificate of compliance (or
                non-compliance) or have a valid occupation certificate less than
                3 years old.
              </Typography>
              <Typography component="li">
                Where the property is tenanted, a copy of the residential lease
                should be included in the contract.
              </Typography>
              <Typography component="li">
                Vendors who sell property off the plan need to attach a
                disclosure statement to the contract that outlines key
                information such as sunset dates, conditional events, and
                provide draft strata plan, proposed schedule of finishes and
                draft by-laws.
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              Prior to settlement, the Vendor is required to provide the
              Purchaser with:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                a clear Land Tax certificate
              </Typography>
              <Typography component="li">
                a clear Foreign Resident Capital Gains Withholding Payments
                Certificate – This is mandatory for all property over $750,000.
                If the clearance certificate is not provided then the Purchaser
                must withhold 12.5% of the purchase price and forward this
                payment to the ATO.
              </Typography>
              <Typography component="li">
                Where GST is required to be paid by the Vendor then the
                Purchaser needs to withhold the GST payment from the purchase
                funds and pay this to the ATO.
              </Typography>
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Additional issues in sale of commercial property
            </Typography>
            <Typography component="p" paragraph>
              The sale of commercial property should be carefully considered for
              GST considerations.
            </Typography>
            <Typography component="p" paragraph>
              The ABN of the Vendor and Purchaser must be identified on the
              front page of the contract. If there is a tenant, a current lease
              should be included in the contract.
            </Typography>
            <Typography component="p" paragraph>
              We will depend on your Accountant’s advice for whether the sale is
              liable to GST or is GST free. Where the commercial property
              attracts GST, then the Purchaser must provide the 10% GST payment
              on settlement and the Vendor has to provide the tax invoice for
              this purpose.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Transfer of property between parties
            </Typography>
            <Typography component="p" paragraph>
              Where there is a transfer of property between related parties,
              then a valuation of the property is required for stamp duty
              purposes.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Taxation issues on property purchase/sale
            </Typography>
            <Typography component="p" paragraph>
              We are not financial advisers and you should check with your
              accountant on the following taxation issues in your property
              purchase:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                Concessions for first home owners
              </Typography>
              <Typography component="li">
                Land tax issues for residential and non-residential properties
              </Typography>
              <Typography component="li">
                Tax benefits and also capital gains tax implications where you
                use part of your home as a home office
              </Typography>
              <Typography component="li">
                Capital gains tax where you are purchasing/selling a residential
                property for investment or it is a commercial property
              </Typography>
              <Typography component="li">
                Purchase/sale as a foreign investor - additional costs and also
                initial requirement for approval from the Foreign Investment
                Review Board for the purchase of property.
              </Typography>
              <Typography component="li">
                GST payments for off the plan purchases
              </Typography>
              <Typography component="li">
                GST payments for sale of commercial properties - Where the
                commercial property attracts GST, then the Purchaser must
                provide the 10% GST payment on settlement to the ATO and the
                Vendor has to provide the tax invoice for this purpose.
              </Typography>
              <Typography component="li">
                Purchase of property as joint tenants or as tenants in common
                and taxation implications
              </Typography>
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default ConveyancingPage
